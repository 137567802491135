import { render, staticRenderFns } from "./Upgrade.vue?vue&type=template&id=4e1d3d13&"
import script from "./Upgrade.vue?vue&type=script&lang=js&"
export * from "./Upgrade.vue?vue&type=script&lang=js&"
import style0 from "./Upgrade.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports