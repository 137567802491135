<template>
  <section
    id="pricing-plan"
  >
    <!-- title text and switch button -->
    <div class="text-center">
      <h1 class="mt-5">
        Pricing Plans
      </h1>
      <p class="mb-2 pb-75">
        All plans include 10+ features to manage your organization. Choose the best plan to fit your needs.
      </p>
    </div>
    <!--/ title text and switch button -->

    <!-- pricing plan cards -->
    <b-row class="pricing-card">
      <b-col
        offset-sm-2
        sm="10"
        md="12"
        offset-lg="2"
        lg="10"
        class="mx-auto"
      >
        <b-row>
          <b-col md="4" v-for="fData in fetchData">
            <b-card align="center" 
              :class="fData.popular ? 'popular' : '' " >
              <div v-if="orgData && orgData.package"
                class="pricing-badge text-left"
                :class="fData.popular ? 'float-left' : '' "
              >
                <b-badge  v-if="fData.id == orgData.package.package_id"
                  variant="light-danger"
                  pill
                >
                  Current Plan
                </b-badge>
              </div>

              <div
                v-show="fData.popular"
                class="pricing-badge text-right"
              >
                <b-badge
                  variant="light-primary"
                  pill
                >
                  Popular
                </b-badge>
              </div>

              <!-- img -->
              <b-img
                v-if="fData.price == 0"
                src="@/assets/images/illustration/Pot1.svg"
                class="mb-2 mt-4"
                alt="basic svg img"
              />
              <b-img
                v-else-if="fData.duration == 'Month'"
                src="@/assets/images/illustration/Pot2.svg"
                class="mb-1"
                alt="basic svg img"
              />
              <b-img
                v-else
                src="@/assets/images/illustration/Pot3.svg"
                class="mb-1"
                alt="basic svg img"
              />
              <!--/ img -->
              <h3>{{ fData.name }}</h3>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                  <span class="pricing-basic-value font-weight-bolder text-primary">{{ fData.price }}</span>
                  <sub v-if="fData.duration" class="pricing-duration text-body font-medium-1 font-weight-bold">/{{ fData.duration }}</sub>
                </div>
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <b-list-group class="list-group-circle text-left">
                <b-list-group-item
                  v-for="(module,index) in fData.modules"
                  :key="index"
                >
                  {{ module.name }}
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->

              <!-- buttons -->
              <b-button v-if="orgData && orgData.package && fData.id == orgData.package.package_id && form.package_id != fData.id"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                class="mt-2"
                variant="outline-success"
                @click="setMonth(fData.id)"
              >
                Renew
              </b-button>
              <b-button v-else-if="form.package_id != fData.id"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                class="mt-2"
                :variant="fData.popular ? 'primary' : 'outline-primary' "
                @click="setMonth(fData.id)"
              >
                Upgrade
              </b-button>
              <div v-if="inputShow && form.package_id==fData.id" class="mt-2 text-left">
                <p>Total Cost: ${{total_price.toFixed(2)}} <span v-if="month > 0" >for {{month}} month(s)</span></p>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  block
                  class="mt-2"
                  variant="primary"
                >
                  Confirm
                </b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!--/ pricing plan cards -->

  </section>
</template>

<script>
import {
  BFormCheckbox, BRow, BCol, BCard, BImg, BCardText, BListGroup, BListGroupItem, BButton, BBadge, BForm, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import orgStoreModule from '../orgStoreModule'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
/* eslint-disable global-require */

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BInputGroup,
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    AppCollapseItem,
    AppCollapse,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      inputShow: false,
      total_price: '',
      month: ''
    }
  },
  methods: {
    setMonth(package_id) {
      this.form.package_id = package_id
        store.dispatch('app-org/getUpgradePackageCost', {package_id: package_id})
          .then(response => { 
            this.total_price = response.data.data.cost,
            this.month = response.data.data.month
          })
          .catch(error => {
            if (error.response.status === 404) {
              this.total_price = ''
            }
          })

        this.inputShow = true;
        this.form.total_price = this.total_price ? (this.total_price).toFixed(2) : this.total_price
    },
  },
  setup() {
    const fetchData = ref(null)
    const orgData = ref(null)

    const ORG_APP_STORE_MODULE_NAME = 'app-org'

    // Register module
    if (!store.hasModule(ORG_APP_STORE_MODULE_NAME)) store.registerModule(ORG_APP_STORE_MODULE_NAME, orgStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ORG_APP_STORE_MODULE_NAME)) store.unregisterModule(ORG_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-org/fetchOrgProfile')
      .then(response => { orgData.value = response.data.data })
      .catch(error => {
        if (error.response.status === 404) {
          orgData.value = undefined
        }
      })

    store.dispatch('app-org/packageList')
      .then(response => { fetchData.value = response.data.data })
      .catch(error => {
        if (error.response.status === 404) {
          fetchData.value = undefined
        }
      })
    
    var min_month;
    var plan;
    var blankData = {
      month: '',
      package_id: '',
      total_price: '',
    }

    var form = blankData
    const resetData = () => {
      form = blankData 
    }

    return {
      fetchData,
      orgData,
      min_month,
      plan,
      form,
      resetData
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
